// Initializes custom facet logic
// This includes collapsing all facets on mount and only allowing one facet menu open at a time
export function initFacets(el) {
  if (!el) return;
  collapseFilters(el);

  const filterboxes = el.querySelectorAll('.yxt-FilterBox-filter');

  for (const box of filterboxes) {
    // Need to add event listener to filterboxes because facet buttons are re-rendered upon any change
    box.addEventListener('mousedown', (e) => {
      if (e.origin != 'collapseFilters') {
        let filterFieldset = box.querySelector('.yxt-FilterOptions-fieldSet');
        collapseFilters(el, filterFieldset)
      }
    })
  }
}

// Collapses all expanded facet menus except for current one
export function collapseFilters(el, currentFieldset = null) {
  if (!el) return;
  const fieldsets = el.querySelectorAll('.yxt-FilterOptions-fieldSet');
  for (const fieldset of fieldsets) {
    const expandButton = fieldset.querySelector('.yxt-FilterOptions-clickableLegend');
    const menu = fieldset.querySelector('.js-yxt-FilterOptions-container');

    if (!expandButton || !menu) continue;

    // We need to trigger a click event to get the answers state to update correctly in the backend
    // No analytics are associated with expanding facet buttons so this is safe to do
    const clickEvent = new Event('mousedown', {"bubbles": true, "cancelable": false});
    clickEvent.button = 0;
    clickEvent.origin = 'collapseFilters';

    if (fieldset === currentFieldset) continue;

    // If menu is open and is not the current menu, click to close it
    if (!menu.classList.contains('yxt-FilterOptions--collapsed')) {
      expandButton.dispatchEvent(clickEvent);
    }
  }
}
